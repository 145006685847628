<template>
  <div>
    <h4>Search:
      <strong>
        {{ search.host ? search.host.name : '' }}
        {{ search.host && search.department ? '+' : '' }}  {{ search.department ? search.department.name : '' }}
        {{ search.host && search.workplace ? '+' : '' }} {{ search.workplace ? search.workplace.name : '' }}
        {{ search.hazard && search.workplace || search.hazard && search.host ? '+' : '' }} {{ search.hazard ? search.hazard.name : '' }}
      </strong>
      <small>
        <a
          href="javascript:;"
          class="btn btn-flat-danger float-end"
          @click="removeSearch"
        >
          <i data-feather="trash-2" /> Delete search
        </a>
      </small>
    </h4>
    <div
      v-if="!isForm"
      class="mb-1"
    >
      <label
        for=""
        class="block-label"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="select or deselect all"
      >
        <input
          v-model="search.share_all"
          type="checkbox"
        > Share with all
      </label>
      <label
        v-if="!isForm && search.host && !search.department && !search.workplace && !search.hazard"
        for=""
        class="block-label ms-1"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="select or deselect all"
      >
        <input
          v-model="search.share_all_academic"
          type="checkbox"
        > Share with all academic departments
      </label>
    </div>
    <div
      v-if="!search.share_all && !search.share_all_academic"
      class="table-responsive-sm mt-2"
    >
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th class="check__column">
              <label
                for=""
                class="block-label"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="select or deselect all"
              ><input
                v-model="search.all"
                type="checkbox"
              ></label>
            </th>
            <th>Name</th>
            <th>Host</th>
            <th>Workplaces</th>
            <th>Hazards</th>
            <th class="text-end">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="newUser, index in search.users"
            :key="Math.floor(index * Date.now()).toString(36)"
          >
            <td><label
              for=""
              class="block-label"
            ><input
              type="checkbox"
              :checked="users.findIndex(x => x.id === newUser.id) >= 0"
              @click="toggleUser($event.target.checked, newUser)"
            ></label></td>
            <td>{{ newUser.name }}</td>
            <td>{{ newUser.hosts ? newUser.hosts.map(el => el.name).join(', ') : '' }}</td>
            <td>{{ newUser.workplaces ? newUser.workplaces.map(el => el.name).join(', ') : '' }}</td>
            <td>{{ newUser.hazards ? newUser.hazards.map(el => el.name).join(', ') : '' }}</td>
            <td class="text-end">
              <a
                :href="$router.resolve({name: 'researcher.dashboard', params: { id: newUser.id }, query: { actAs: $route.query.actAs }}).href"
                target="_blank"
                class="btn btn-icon btn-icon rounded-circle btn-flat-dark me-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="open researcher profile"
              ><i data-feather="eye" /></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    search: { type: Object, required: false, default: () => {} },
    users: { type: Array, required: false, default: () => [] },
    index: { type: Number, required: true },
    isForm: { type: Boolean, required: false, default: false },
  },
  methods: {
    toggleUser(active, user) {
      if (!active) {
        this.search.all = false
      }

      this.$emit('toggleUser', {
        active,
        user,
      })
    },
    removeSearch() {
      this.$emit('removeSearch', this.index)
    },
  },
}
</script>
